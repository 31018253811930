import {
  Datagrid,
  Empty,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  useTranslate,
} from 'react-admin'

import {useUserDisplayName} from '../hooks/useUserDisplayName'
import ExtendedPagination from './components/ExtendedPagination'

const OrganizationMerchantUsersList = () => {
  const userDisplayName = useUserDisplayName()
  return (
    <List
      actions={false}
      exporter={false}
      filter={{isOwner: true}}
      pagination={<ExtendedPagination rowsPerPageOptions={[]} />}
      perPage={10}
      resource="organization_merchant_users"
      sort={{field: 'merchant.name', order: 'ASC'}}
    >
      <Datagrid bulkActionButtons={false} empty={<Empty />}>
        <ReferenceField
          reference="merchants"
          sortBy="merchant.name"
          sortByOrder="ASC"
          source="merchantId"
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label={useTranslate()(
            'resources.organization_merchant_users.customFields.name.label'
          )}
          // @ts-ignore
          render={merchantUser => userDisplayName({merchantUser})}
          sortBy="firstName"
          sortByOrder="ASC"
        />
        <TextField source="emailAddress" />
      </Datagrid>
    </List>
  )
}

export default OrganizationMerchantUsersList
