import {useMutation, useQuery} from '@apollo/client'
import {InputAdornment, Link, Tooltip, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import gql from 'graphql-tag'
import {useCallback, useMemo} from 'react'
import {
  Create,
  Datagrid,
  EditContextProvider,
  FilterLiveSearch,
  FunctionField,
  required,
  SaveButton,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  useEditController,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate,
} from 'react-admin'
import {FieldValues, useFormContext} from 'react-hook-form'

import {
  ChatMessageTemplateField,
  ChatMessageTemplateInput,
} from '../components/chatMessageTemplate/ChatMessageTemplateEditor'
import {
  ApprovedIcon,
  ChevronRightIcon,
  ClockIcon,
  RejectedIcon,
  SearchIcon,
} from '../components/icons'
import SubtitledInput from '../components/SubtitledInput'
import {useCompileChatMessageText} from '../hooks/useCompileChatMessageText'
import useHasPermission from '../hooks/useHasPermission'
import useIntervalRefresh from '../hooks/useIntervalRefresh'
import useIsDesktop from '../hooks/useIsDesktop'
import {ChatMessageTemplates} from '../types/graphqlSchema'
import {EventCategory, trackEvent} from '../utils/tracking'
import DeleteWithConfirmButton from './components/DeleteWithConfirmButton'
import ExtendedList from './components/ExtendedList'
import ExtendedPagination from './components/ExtendedPagination'
import {CreateHeader, ShowHeader} from './components/ResourceHeader'

const ChatMessageTemplatesList = props => {
  useIntervalRefresh(60000)
  const translate = useTranslate()
  const {data: {chat_message_templates: chatMessageTemplates = []} = {}} = useQuery(
    DISTINCT_CHAT_MESSAGE_TEMPLATE_LANGUAGES_QUERY,
    {fetchPolicy: 'cache-first'},
  )
  const languageChoices = useMemo(
    () => chatMessageTemplates.map(({language}) => ({
      id: language,
      name: translate(`languages.${language}`),
    })),
    [chatMessageTemplates, translate]
  )
  const filter = useMemo(() => ({isDeleted: false, isListed: true}), [])
  return (
    <ExtendedList
      {...props}
      description={
        <Typography color="textSecondary">
          {translate('resources.chat_message_templates.description')}
        </Typography>
      }
      filter={filter}
      filters={[
        <FilterLiveSearch
          // @ts-ignore: 'InputProps' does not exist
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="secondary" />
              </InputAdornment>
            ),
            onKeyDown: e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
              }
            },
          }}
          alwaysOn
          key="fullText"
          source={[
            "headline@_ilike",
            "name@_ilike",
            "text@_ilike",
            "buttonText@_ilike",
          ].join(',')}
        />,
        <SelectInput
          choices={TYPES.map(type => ({
            id: type,
            name: translate(`resources.chat_message_templates.types.${type}`),
          }))}
          isRequired
          key="type"
          label={translate('resources.chat_message_templates.fields.category')}
          optionText="name"
          optionValue="id"
          source="type"
          sx={{'& span[aria-hidden="true"]': {display: 'none'}}}
        />,
        <SelectInput
          choices={STATUSES.map(status => ({
            id: status,
            name: translate(`whatsapp_message_templates.statusLabels.${status}`),
          }))}
          isRequired
          key="status"
          label={translate('resources.chat_message_templates.fields.status')}
          optionText="name"
          optionValue="id"
          source="status"
          sx={{'& span[aria-hidden="true"]': {display: 'none'}}}
        />,
        <SelectInput
          choices={languageChoices}
          isRequired
          key="language"
          label={translate('resources.chat_message_templates.fields.language')}
          optionText="name"
          optionValue="id"
          source="language"
          sx={{'& span[aria-hidden="true"]': {display: 'none'}}}
        />,
      ]}
      onCreateButtonClick={() => trackEvent(
        'CREATE_TEMPLATE',
        EventCategory.TEMPLATE,
        'TEMPLATES_PAGE'
      )}
      pagination={<ExtendedPagination rowsPerPageOptions={[]} />}
      perPage={25}
      sort={{field: 'insertionTimestamp', order: 'DESC'}}
    >
      <ChatMessageTemplateDatagrid />
    </ExtendedList>
  )
}

const ChatMessageTemplateDatagrid = () => {
  const styles = useStyles()
  const isDesktop = useIsDesktop()
  const redirect = useRedirect()
  const translate = useTranslate()
  return (
    <Datagrid bulkActionButtons={false} className={styles.table} rowClick={false}>
      <TextField source="name" variant="body1"/>
      <FunctionField render={() => <StatusIconField />} source="approvalStatus"/>
      {isDesktop && <FunctionField render={() => <TypeLabelField />} source="type"/>}
      {isDesktop && (
        <FunctionField<ChatMessageTemplates>
          render={
            ({id, status, type} = {} as ChatMessageTemplates) => (
              (type === 'MARKETING') && (status === 'APPROVED')
            ) &&
              <Link onClick={() => redirect(`/campaigns/create?templateId=${id}`)}>
                {translate('resources.chat_message_templates.startCampaign')}
              </Link>
          }
        />
      )}
      <ShowButton
        icon={<ChevronRightIcon color="action" />}
        label=""
        // @ts-ignore
        source="showIconButton"
      />
    </Datagrid>
  )
}

const ChatMessageTemplateShow = () => {
  const translate = useTranslate()
  const redirect = useRedirect()
  const editController = useEditController()
  const [softDelete] = useMutation(
    SOFT_DELETE_CHAT_MESSAGE_TEMPLATE_MUTATION,
    {
      onCompleted: () => redirect('/chat_message_templates'),
      onError: console.error,
      variables: {chatMessageTemplateId: editController.record?.id},
    }
  )
  return (
    <Show actions={<ChatMessageTemplatesShowHeader />}>
      <SimpleShowLayout>
        <TextField source="name" variant="body1" />
        <FunctionField
          render={r => translate(
            `resources.chat_message_templates.types.${r?.type}`, {_: ''}
          )}
          source="category"
          variant="body1"
        />
        <StatusDescriptionField addLabel source="status" />
        <ChatMessageTemplateField />
        {useHasPermission('delete', 'chat_message_templates') && (
          <Toolbar>
            <EditContextProvider value={editController}>
              <DeleteWithConfirmButton
                confirmContent={
                  translate('resources.chat_message_templates.confirmDeletion')
                }
                confirmTitle={record => record.name}
                onConfirmDelete={softDelete}
              />
            </EditContextProvider>
          </Toolbar>
        )}
      </SimpleShowLayout>
    </Show>
  )
}

const ChatMessageTemplatesShowHeader = () => (
  <ShowHeader title={useRecordContext()?.name} />
)

const ChatMessageTemplateCreate = () => {
  const redirect = useRedirect()
  const notify = useNotify()
  const translate = useTranslate()
  const [upsertChatMessageTemplate] = useMutation(CHAT_MESSAGE_TEMPLATE_UPSERT_MUTATION)
  const {placeholderTypeToLabel} = useCompileChatMessageText()
  const autocompletedUrl = originalUrl => {
    // TODO: consider using a custom validation in ChatMessageTemplateEditor, forcing the
    // informed URL to be either prefixed with an 'https://', or use no protocol at all
    // (the platform would automatically prefix those with an 'https://'). After
    // experiments with different valid URLs, these were the following template approval
    // statuses after ~ 10 minutes:
    //
    // * "https://flinkit.de": APPROVED
    // * "flinkit.de:8001/neues": APPROVED
    // * "http://flinkit.de:8000/website": SUBMITTED
    //
    // Hypothesis: 'http://' (and possibly other protocols) might undergo closer inspection
    // by 360d or Meta
    try {
      return originalUrl && new URL(originalUrl).href
    }
    catch (e) {
      if (!(e instanceof TypeError)) {
        throw e
      }
      return `https://${originalUrl}`
    }
  }
  const save = useCallback(
    async ({
      addHeaderMediaSwitch,
      buttonText,
      buttonUrl,
      headerMediaBase64,
      headerMediaMimeType,
      headline,
      name,
      text,
      type,
    }: FieldValues) => {
      if (addHeaderMediaSwitch && !headerMediaBase64) {
        notify(
          'resources.chat_message_templates.create.imageRequiredForHeaderMediaSwitch',
          {type: 'error'},
        )
        return
      }
      const placeholderTranslatedText = Object.entries(placeholderTypeToLabel)
        .reduce(
          (translatedText, [placeholderType, placeholderLabel]) => (
            translatedText?.replaceAll(
              `{{%${placeholderLabel}%}}`,
              `{{%${placeholderType}%}}`,
            )
          ),
          text,
        )
      try {
        await upsertChatMessageTemplate({
          variables: {
            buttonText,
            buttonUrl: autocompletedUrl(buttonUrl) || undefined,
            headerMediaBase64,
            headerMediaMimeType,
            headline,
            name: name.trim(),
            text: placeholderTranslatedText,
            type,
          },
        })
        notify('resources.chat_message_templates.create.success')
        redirect('list', "/chat_message_templates")
      }
      catch (e: any) {
        const errorCode = e.graphQLErrors?.[0]?.extensions[0]?.code
        const message =
          translate(
            `resources.chat_message_templates.errorMessages.${errorCode}`,
            {_: ''}
          ) ?? "resources.chat_message_templates.create.success"
        notify(message, {type: 'error'})
      }
    },
    [
      notify,
      placeholderTypeToLabel,
      redirect,
      translate,
      upsertChatMessageTemplate,
    ]
  )
  return (
    <Create actions={<CreateHeader />} redirect="list" >
      <SimpleForm
        onSubmit={save}
        toolbar={<ChatMessageTemplateCreateToolbar />}
        mode="all"
        reValidateMode="onBlur"
      >
        <SubtitledInput
          subtitle={translate('resources.chat_message_templates.create.nameSubtitle')}
          title={translate('resources.chat_message_templates.fields.name')}
        >
          <TextInput
            source="name"
            label={translate('resources.chat_message_templates.create.inputs.name.label')}
            validate={required()}
          />
        </SubtitledInput>
        <SubtitledInput
          subtitle={translate('resources.chat_message_templates.create.typeSubtitle')}
          title={translate('resources.chat_message_templates.fields.type')}
        >
          <SelectInput
            choices={
              TYPES
                .map((type, index) => ({
                  id: index,
                  key: type,
                  name: translate(`resources.chat_message_templates.types.${type}`),
                }))
                .sort((a, b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0))
            }
            defaultValue={DEFAULT_TEMPLATE_TYPE}
            label={translate('resources.chat_message_templates.create.inputs.type.label')}
            optionValue="key"
            source="type"
            validate={required()}
          />
        </SubtitledInput>
        <ChatMessageTemplateInput />
      </SimpleForm>
    </Create>
  )
}

const ChatMessageTemplateCreateToolbar = () => {
  const {formState: {isValid}} = useFormContext()
  return (
    <Toolbar>
      <SaveButton
        disabled={!isValid}
        label={useTranslate()('resources.chat_message_templates.create.createButton')}
        type="submit"
      />
    </Toolbar>
  )
}

const StatusDescriptionField = props => {
  const styles = useStyles()
  const translate = useTranslate()
  const {status} = useRecordContext<ChatMessageTemplates>() ?? {}
  return (
    <span className={styles.statusDescriptionField}>
      <StatusIconField {...props} />
      <Typography variant="body1">
        {translate(`whatsapp_message_templates.statusLabels.${status}`)}:{' '}
        {translate(`whatsapp_message_templates.statusDescriptions.${status}`)}
      </Typography>
    </span>
  )
}

const TypeLabelField = () => (
  <Typography variant="body1">
    {useTranslate()(`resources.chat_message_templates.types.${useRecordContext()?.type}`)}
  </Typography>
)

const StatusIconField = () => {
  const {status} = useRecordContext<ChatMessageTemplates>() ?? {}
  const translate = useTranslate()
  const icon = (() => {
    switch (status) {
      case 'APPROVED': return <ApprovedIcon color="success" />
      case 'REJECTED': return <RejectedIcon color="error" />
      default: return <ClockIcon />
    }
  })()
  return (
    <Tooltip
      arrow
      title={
        translate(`whatsapp_message_templates.statusDescriptions.${status}`)
      }
    >
      {/* TODO: remove span once our SvgIcon component handles forwardRef well */}
      <span>{icon}</span>
    </Tooltip>
  )
}

const useStyles = makeStyles(theme => ({
  statusDescriptionField: {
    '& .MuiSvgIcon-root': {
      marginRight: theme.remSpacing(1),
    },
    display: 'inline-flex',
  },
  table: {
    '& .RaDatagrid-table': {
      [theme.breakpoints.down('lg')]: {
        '& td': {
          verticalAlign: 'middle',
        },
        '& td:nth-of-type(1)': {
          textAlign: 'left',
        },
        '& th:nth-of-type(1)': {
          width: '50%!important',
        },
        '& th:nth-of-type(2)': {
          width: theme.remSpacing(2),
        },
        '& th:nth-of-type(3)': {
          width: theme.remSpacing(5),
        },
        marginBottom: theme.typography.pxToRem(50),
      },
      '& th:nth-of-type(1)': {
        width: '40%',
      },
    },
  },
}))

// TODO: Load this list via graphql query from chat_message_template_types table
const TYPES = ['UTILITY', 'MARKETING'] // 'UTILITY' should come first!

const DEFAULT_TEMPLATE_TYPE = TYPES[0]

const STATUSES = ['PENDING', 'APPROVED', 'REJECTED', 'DELETED']

const CHAT_MESSAGE_TEMPLATE_UPSERT_MUTATION = gql`
  mutation(
    $buttonUrl: String
    $buttonText: String
    $type: String!
    $name: String!
    $headerMediaBase64: String
    $headerMediaMimeType: String
    $headline: String
    $text: String!
  ) {
    insertChatMessageTemplate(
      buttonUrl: $buttonUrl
      buttonText: $buttonText
      name: $name
      headerMediaBase64: $headerMediaBase64
      headerMediaMimeType: $headerMediaMimeType
      headline: $headline
      text: $text
      type_: $type
    ) {chatMessageTemplateId}
  }
`

const SOFT_DELETE_CHAT_MESSAGE_TEMPLATE_MUTATION = gql`
  mutation($chatMessageTemplateId: uuid!) {
    update_chat_message_templates_by_pk(
      pk_columns: {id: $chatMessageTemplateId}, _set: {isDeleted: true}
    ){id}
  }
`

const DISTINCT_CHAT_MESSAGE_TEMPLATE_LANGUAGES_QUERY = gql`
  query{
    chat_message_templates(
      distinct_on: language
      where: {language: {_is_null: false}}
    ){language}
  }
`

export {
  ChatMessageTemplateCreate,
  ChatMessageTemplateShow,
  ChatMessageTemplatesList,
}
