import {LocalizationProvider, TimePicker} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3'
import {isValid} from 'date-fns'
import {FC} from 'react'
import {InputHelperText, InputProps, useInput, useLocale, useTranslate} from 'react-admin'

import {DATE_FNS_LOCALES_FROM_I18N_LANGUAGES} from '../../utils/i18nProvider'
import {isNativeIosApp} from '../../utils/platform'

const TimeInput: FC<TimeInputProps> = ({
  className,
  disabled = false,
  fullWidth = false,
  InputProps = {},
  label, // TODO: Use i18nProvider w/ resource/source name by default
  source,
  validate,
  variant = 'filled',
}) => {
  const translate = useTranslate()
  const locale = useLocale()
  const {
    field: {onChange, value},
    fieldState: {error, isDirty},
  } = useInput({
    format: (value: string | null) => {
      if (!value) return value
      const [hours, minutes] = value.split(':')
      const date = new Date()
      date.setSeconds(0)
      date.setHours(Number.parseInt(hours))
      date.setMinutes(Number.parseInt(minutes))
      return date
    },
    parse: (value: Date) => isValid(value) ? value.toLocaleTimeString(locale) : null,
    source,
    validate: (value, allValues, meta) => {
      if (!value && InputProps.isRequired) return translate('validations.required')
      if (typeof validate === 'function') return validate(value, allValues, meta)
      if (Array.isArray(validate)) validate.forEach(f => f(value, allValues, meta))
    },
  })
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={DATE_FNS_LOCALES_FROM_I18N_LANGUAGES[locale]}
    >
      <TimePicker
        className={className}
        disabled={disabled}
        label={label ?? source}
        name={source}
        onChange={onChange}
        slotProps={{
          textField: {
            error: (isDirty && !!error),
            fullWidth,
            helperText: isDirty && error && <InputHelperText error={error.message} />,
            required: InputProps.isRequired,
            slotProps: {inputLabel: {shrink: !isNativeIosApp}},
            variant,
          },
        }}
        value={value}
      />
    </LocalizationProvider>
  )
}

export type ValidationFunction = (
  value: string, allValues?: Record<string, any>, meta?: any
) => any | Promise<any>

interface TimeInputProps {
  InputProps: Partial<InputProps>,
  className?: string,
  disabled?: boolean,
  fullWidth?: boolean,
  label?: string,
  source: string,
  validate?: ValidationFunction | ValidationFunction[]
  variant?: 'filled' | 'outlined' | 'standard'
}

export default TimeInput
