import {useMutation} from '@apollo/client'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import gql from 'graphql-tag'
import {
  Create,
  regex,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import {FieldValues} from 'react-hook-form'

import useSessionMerchantUser from '../hooks/useSessionMerchantUser'
import {MutationRoot} from '../types/graphqlSchema'
import {EMAIL_ADDRESS_REGEX} from '../utils/consts'

const MerchantsCreate = () => {
  const styles = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const {merchantUser} = useSessionMerchantUser()
  const [mutate] = useMutation<MutationRoot['insert_merchants_one']>(
    MERCHANTS_MUTATION, {
      onCompleted: () => {
        notify('resources.merchants.successMessages.created')
        refresh()
      },
      onError: () => {
        notify('resources.merchants.errorMessages.create', {type: 'error'})
      },
    }
  )
  const save = ({
    companyName, ownerEmailAddress, ownerFirstName, ownerLastName,
  }: FieldValues) => {
    mutate({
      variables: {
        companyName,
        organizationId: merchantUser?.merchant.organizationId,
        ownerEmailAddress,
        ownerFirstName,
        ownerLastName,
      },
    })
  }
  return (
    <Create className={styles.root} resource="merchants">
      <SimpleForm
        onSubmit={save}
        toolbar={
          <SaveButton label={useTranslate()('resources.merchants.actions.create')} />
        }
      >
        <Typography variant="subtitle2">
          {useTranslate()('resources.merchants.location')}
        </Typography>
        <TextInput source="companyName" validate={required()} />
        <Typography variant="subtitle2">
          {useTranslate()('resources.merchants.owner')}
        </Typography>
        <TextInput source="ownerFirstName" validate={required()} />
        <TextInput source="ownerLastName" validate={required()} />
        <TextInput
          parse={v => v?.toLowerCase()}
          source="ownerEmailAddress"
          validate={[
            regex(EMAIL_ADDRESS_REGEX, useTranslate()('validations.emailAddress')),
            required(),
          ]}
        />
      </SimpleForm>
    </Create>
  )
}

const MERCHANTS_MUTATION = gql`
  mutation(
    $companyName: String!,
    $ownerEmailAddress: String!,
    $ownerFirstName: String!,
    $ownerLastName: String!,
    $organizationId: uuid!
  ) {
    insert_merchants_one(
      object: {
        name: $companyName,
        merchantUsers: {
          data: {
            emailAddress: $ownerEmailAddress,
            firstName: $ownerFirstName,
            isOwner: true,
            lastName: $ownerLastName
          }
        }
        organizationId: $organizationId
      }
    ){id}
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiButton-root': {
      marginBottom: theme.remSpacing(4),
    },
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

export {MerchantsCreate}
