import {Box, Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {FC, MouseEventHandler, ReactNode} from 'react'
import {CreateButton, useResourceContext, useTranslate} from 'react-admin'

import ResourceTitle from './ResourceTitle'

const ResourceHeader: FC<ResourceHeaderProps> = ({
  children,
  className,
  hasCreateButton,
  hasTitle = true,
  onCreateButtonClick,
  title,
}) => {
  const resource = useResourceContext()
  const styles = useStyles()
  const translate = useTranslate()
  return (
    <Box className={`${styles.root} ${className ?? ''}`}>
      {hasTitle && <ResourceTitle title={title} />}
      <Grid container>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="start"
          width="100%"
        >
          <Grid item lg={hasCreateButton ? 5 : 12} md={hasCreateButton ? 8 : 12}>
            {children}
          </Grid>
          {
            // TODO: study whether we want to keep this create button component in place.
          }
          {hasCreateButton &&
            <Grid item lg={7} md={4}>
              <Box textAlign="end">
                <CreateButton
                  label={translate(`resources.${resource}.action.create`)}
                  onClick={onCreateButtonClick}
                  size="medium"
                  variant="contained"
                />
              </Box>
            </Grid>
          }
        </Box>
      </Grid>
    </Box>
  )
}

const CreateHeader = () => (
  <ResourceHeader
    title={useTranslate()(`resources.${useResourceContext()}.createFormTitle`)}
  />
)

const ShowHeader: FC<Pick<ResourceHeaderProps, 'title'>> = ({title}) => (
  <ResourceHeader title={title} />
)

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.remSpacing(3),
    marginTop: theme.remSpacing(3),
    width: '100%',
  },
  titleBox: {
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
    '& > a > svg': {
      borderRight: `1px solid ${theme.palette.disabled.main}`,
      color: theme.palette.primary.main,
      marginRight: theme.remSpacing(3),
      paddingBottom: theme.remSpacing(1.5),
      paddingRight: theme.remSpacing(3),
      paddingTop: theme.remSpacing(1.5),
    },
    marginBottom: theme.remSpacing(1),
  },
}))

interface ResourceHeaderProps {
  children?: ReactNode
  className?: string
  hasCreateButton?: boolean
  hasTitle?: boolean,
  onCreateButtonClick?: MouseEventHandler<HTMLAnchorElement>
  title?: ReactNode
}

export {CreateHeader, ShowHeader}
