import {useMutation} from '@apollo/client'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {AutoSave} from '@react-admin/ra-form-layout'
import {
  ReferenceManyToManyField,
  ReferenceManyToManyInput,
} from '@react-admin/ra-relationships'
import gql from 'graphql-tag'
import {sortBy} from 'lodash-es'
import {FC, useCallback, useState} from 'react'
import {
  AutocompleteArrayInput,
  Create,
  FunctionField,
  RadioButtonGroupInput,
  regex,
  required,
  SaveButton,
  SaveContext,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextInput,
  useGetList,
  useLocale,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import {FieldValues, useFormContext, useWatch} from 'react-hook-form'

import ExtendedEdit from '../components/ExtendedEdit'
import useSessionMerchantUser from '../hooks/useSessionMerchantUser'
import {Channels} from '../types/graphqlSchema'
import {EMAIL_ADDRESS_REGEX} from '../utils/consts'
import {actionErrorCode} from '../utils/errors'
import {SUPPORTED_I18N_LANGUAGES} from '../utils/i18nProvider'

const MerchantUserCreate = () => {
  const styles = useStyles()
  const {merchantUser} = useSessionMerchantUser()
  const translate = useTranslate()
  const [isLimitedToContactGroups, setIsLimitedToSpecificContactGroups] = useState(false)
  const {data: contactGroups = []} = useGetList('contact_groups')
  return (
    <Create
      className={styles.merchantUserCreateRoot}
      redirect={false}
      resource="merchant_users"
    >
      <SimpleForm
        defaultValues={{merchantId: merchantUser?.merchant?.id}}
        toolbar={<MerchantUserSaveOrRestoreButton />}
      >
        <TextInput source="firstName" validate={required()}/>
        <TextInput source="lastName" validate={required()} />
        <TextInput
          parse={v => v?.toLowerCase()}
          source="emailAddress"
          validate={[regex(
            EMAIL_ADDRESS_REGEX,
            translate('resources.merchant_users.errorMessages.invalidEmailAddress')
          ), required()]}
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={isLimitedToContactGroups}
              disabled={!contactGroups.length}
              onChange={
                event => setIsLimitedToSpecificContactGroups(event.target.checked)
              }
            />
          )}
          label={translate(
            'dialogs.profile.companySettings.addMerchantUser.restrictContactGroups'
          )}
        />
        {isLimitedToContactGroups && (
          <ReferenceManyToManyInput
            perPage={-1}
            perPageChoices={-1}
            reference="contact_groups"
            sortChoices={{field: 'name', order: 'ASC'}}
            through="merchant_user_contact_groups"
            using="merchantUserId,contactGroupId"
          >
            <AutocompleteArrayInput
              filterToQuery={q => ({'name@_ilike': q})}
              label="resources.contactGroups.name"
              optionText="name"
              validate={required()}
            />
          </ReferenceManyToManyInput>
        )}
      </SimpleForm>
    </Create>
  )
}

const MerchantUserSaveOrRestoreButton = ({...props}) => {
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const {reset} = useFormContext()
  const {emailAddress, firstName, lastName} = useWatch()
  const [restoreMerchantUser] = useMutation(
    RESTORE_DELETED_MERCHANT_USER_MUTATION, {
      onCompleted: ({restoreMerchantUser: {isRestored}}) => {
        if (isRestored) {
          notify('resources.merchant_users.successMessages.created')
          reset()
          refresh()
        }
        else {
          notify(
            'resources.merchant_users.errorMessages.' +
            'MERCHANT_USER_EMAIL_ADDRESS_ALREADY_EXISTS',
            {type: 'error'},
          )
        }
      },
      variables: {emailAddress, firstName, lastName},
    }
  )
  return (
    <SaveButton
      label={translate('resources.merchant_users.actions.create')}
      mutationOptions={{
        onError: e => {
          if ((e as Error).message.endsWith(
            'Uniqueness violation. duplicate key value violates ' +
            'unique constraint "merchant_users_email_address_key"'
          )) {
            restoreMerchantUser()
          }
        },
        onSuccess: () => {
          notify('resources.merchant_users.successMessages.created')
          reset()
          refresh()
        },
      }}
      type="button"
      {...props}
    />
  )
}

const MerchantUserEdit: FC<{id: string}> = ({id}) => {
  const styles = useStyles()
  const translate = useTranslate()
  return (
    <ExtendedEdit
      id={id}
      redirect={false}
      resource="merchant_users"
      title={translate('resources.merchant_users.edit.title')}
    >
      <SimpleForm
        className={styles.merchantUserEditRoot}
        resetOptions={{keepDirtyValues: true}}
        toolbar={(
          <AutoSave
            confirmationDuration={false}
            debounce={1000}
            typographyProps={{display: 'none'}}
          />
        )}
      >
        <TextInput source="firstName" validate={required()}/>
        <TextInput source="lastName" />
        <MerchantUserEmailEdit />
        <ReferenceManyToManyField
          reference="channels"
          sort={{field: "channel.whatsappAccount.phoneNumber", order: "DESC"}}
          through="channel_merchant_users"
          using="merchantUserId,channelId"
        >
          <Typography className={styles.phoneNumbersTitle} variant="subtitle2">
            {translate(
              'resources.merchant_users.customFields.myFlinkitNumbers.label'
            )}
          </Typography>
          <SingleFieldList display="grid" linkType={false}>
            <FunctionField<Channels> render={r =>
              <TextField
                // Shrink the input label when the initial value of r is nullish.
                // See: https://github.com/mui/material-ui/issues/13013
                InputLabelProps={{shrink: !!r?.name}}
                disabled
                label={(r?.name !== r?.whatsappAccount?.phoneNumber) ? r?.name : ''}
                size="small"
                value={r?.whatsappAccount?.phoneNumber}
                variant="filled"
              />
            }
            />
          </SingleFieldList>
        </ReferenceManyToManyField>
        <LanguageSwitcher />
        <Typography
          className={styles.emailNotificationPreferenceOptionsTitle}
          color="textPrimary"
          variant="subtitle2"
        >
          {translate('resources.merchant_users.emailNotification.title')}
        </Typography>
        <EmailNotificationPreferenceOptions />
      </SimpleForm>
    </ExtendedEdit>
  )
}

const MerchantUserEmailEdit = () => {
  const notify = useNotify()
  const styles = useStyles()
  const translate = useTranslate()
  const {
    merchantUser: {emailAddress: currentEmailAddress = ''} = {},
    refetch,
  } = useSessionMerchantUser()
  const [updateMerchantUserEmailAddress, {loading}] = useMutation(
    UPDATE_MERCHANT_USER_EMAIL_ADDRESS_MUTATION,
    {
      onCompleted: () => notify('ra.notification.updated', {type: 'info'}),
      onError(e) {
        const errorCode = actionErrorCode(e)
        notify(
          `resources.merchant_users.errorMessages.${errorCode}`,
          {messageArgs: {_: translate('ra.message.error')}, type: 'error'},
        )
      },
    }
  )
  const save = useCallback(async ({emailAddress}: FieldValues) => {
    if (currentEmailAddress === emailAddress) return
    await updateMerchantUserEmailAddress({variables: {newEmailAddress: emailAddress}})
    await refetch()
  }, [currentEmailAddress, updateMerchantUserEmailAddress, refetch])
  return (
    <Create
      className={styles.updateMerchantUserEmailAddressForm}
      redirect={false}
      resource="merchant_users"
    >
      <SimpleForm
        defaultValues={{emailAddress: currentEmailAddress}}
        toolbar={(
          <MerchantUserEmailAddressSaveButton
            currentEmailAddress={currentEmailAddress}
            disabled={loading}
            save={save}
          />
        )}
      >
        <Typography color="textPrimary" variant="subtitle2">
          {translate('dialogs.profile.merchantUsersSettings.updateEmailAddress.title')}
        </Typography>
        <TextInput
          parse={v => v?.toLowerCase()}
          source="emailAddress"
          validate={[
            regex(
              EMAIL_ADDRESS_REGEX,
              translate('resources.merchant_users.errorMessages.invalidEmailAddress')
            ),
            required(),
          ]}
        />
      </SimpleForm>
    </Create>
  )
}

const MerchantUserEmailAddressSaveButton = ({
  currentEmailAddress = '', disabled = false, save,
}) => {
  const {emailAddress} = useWatch()
  return (
    <SaveContext.Provider value={{save}}>
      <SaveButton
        icon={<></>}
        disabled={disabled || (emailAddress === currentEmailAddress)}
        type="button"
        variant="outlined"
      />
    </SaveContext.Provider>
  )
}

const LanguageSwitcher = () => {
  const styles = useStyles()
  const locale = useLocale()
  const translate = useTranslate()
  return (
    <Box marginTop="1rem">
      <Typography color="textPrimary" variant="subtitle2">
        {translate('resources.merchant_users.fields.language')}
      </Typography>
      <SelectInput
        choices={
          sortBy(
            SUPPORTED_I18N_LANGUAGES
              .map(l => ({id: l, label: translate(`languages.${l}`)})),
            'label',
          )
        }
        className={styles.languageSwitcherSelectInput}
        defaultValue={locale}
        label={translate('resources.merchant_users.chooseLanguage')}
        optionText={({id: language, label}) => (
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>{label}</Typography>
            {locale === language && (
              <Typography className={styles.checkMark}>✓</Typography>
            )}
          </Box>
        )}
        validate={required()}
        source="language"
        sx={{'& span[aria-hidden="true"]': {display: 'none'}}}
      />
    </Box>
  )
}

const EmailNotificationPreferenceOptions = () => {
  const styles = useStyles()
  const choices = [
    {
      description: useTranslate()(
        'resources.merchant_users.emailNotification.choices.reminders.description'
      ),
      id: 'REMINDERS',
      label: useTranslate()(
        'resources.merchant_users.emailNotification.choices.reminders.label'
      ),
    },
    {
      description: useTranslate()(
        'resources.merchant_users.emailNotification.choices.instant.description'
      ),
      id: 'INSTANT',
      label: useTranslate()(
        'resources.merchant_users.emailNotification.choices.instant.label'
      ),
    },
    {
      description: useTranslate()(
        'resources.merchant_users.emailNotification.choices.none.description'
      ),
      id: 'NONE',
      label: useTranslate()(
        'resources.merchant_users.emailNotification.choices.none.label'
      ),
    },
  ]
  return (
    <Paper className={styles.emailNotificationPreferenceOptionsPaper} elevation={2}>
      <RadioButtonGroupInput
        choices={choices}
        label={false}
        options={{className: styles.emailNotificationPreferenceOptions}}
        row={false}
        optionText={choice => (
          <>
            <Typography variant="body1">{choice.label}</Typography>
            <Typography color="textSecondary" variant="caption">
              {choice.description}
            </Typography>
          </>
        )}
        source="emailNotificationPreference"
      />
    </Paper>
  )
}

const UPDATE_MERCHANT_USER_EMAIL_ADDRESS_MUTATION = gql`
  mutation($newEmailAddress: String!){
    updateMerchantUserEmailAddress(newEmailAddress: $newEmailAddress)
  }
`

const RESTORE_DELETED_MERCHANT_USER_MUTATION = gql`
  mutation($emailAddress: String!, $firstName: String!, $lastName: String!){
    restoreMerchantUser(
      emailAddress: $emailAddress
      firstName: $firstName
      lastName: $lastName
    ){isRestored}
  }
`

const useStyles = makeStyles(theme => ({
  checkMark: {},
  contactFilterAll: {
    border: `1.5px solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
  },
  emailNotificationPreferenceOptions: {
    '& .Mui-checked': {
      color: theme.palette.info.main,
    },
    gap: theme.remSpacing(2),
  },
  emailNotificationPreferenceOptionsPaper: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    padding: `0 ${theme.remSpacing(2)}`,
    width: theme.remSpacing(48),
  },
  emailNotificationPreferenceOptionsTitle: {
    padding: `${theme.remSpacing(2)} 0`,
  },
  languageSwitcherSelectInput: {
    // Hide the check mark on the input field itself so that its only displayed
    // on the popup list.
    '& $checkMark': {
      display: 'none',
    },
  },
  merchantUserCreateRoot: {
    '& .MuiCardContent-root': {
      paddingTop: 0,
    },
    '& > div': {
      marginTop: 0,
    },
    paddingLeft: 0,
    paddingRight: 0,
  },
  merchantUserEditRoot: {
    '& .MuiStack-root > div:not(.MuiPaper-root)': {
      width: '100%',
    },
  },
  phoneNumbersTitle: {
    margin: `${theme.remSpacing(2)} 0`,
  },
  updateMerchantUserEmailAddressForm: {
    minWidth: '100%',
    padding: 0,
  },
}))

export {
  MerchantUserCreate,
  MerchantUserEdit,
}
