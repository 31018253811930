import {useMutation, useQuery} from '@apollo/client'
import {
  Box,
  Divider,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {AutoSave} from '@react-admin/ra-form-layout'
import gql from 'graphql-tag'
import {pick} from 'lodash-es'
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state'
import {FC, ReactNode, useCallback, useEffect, useState} from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  DateInput,
  defaultExporter,
  EditButton,
  ExportButton,
  FilterLiveSearch,
  FunctionField,
  Loading,
  Pagination,
  RaRecord,
  ReferenceField,
  ReferenceInput,
  regex,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useCreate,
  useEditContext,
  useGetIdentity,
  useGetList,
  useListContext,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin'
import {FieldValues} from 'react-hook-form'

import BulkDeleteButton from '../components/BulkDeleteButton'
import ExtendedEdit from '../components/ExtendedEdit'
import FileUploadButton from '../components/FileUploadButton'
import {
  ChevronRightIcon,
  DownloadIcon,
  SaveIcon,
  SearchIcon,
  SyncIcon,
  WarningIcon,
} from '../components/icons'
import MultipleChipSelect from '../components/MultipleChipSelect'
import ProgressDialog from '../components/ProgressDialog'
import useHasPermission from '../hooks/useHasPermission'
import useIsDesktop from '../hooks/useIsDesktop'
import useIsOwner from '../hooks/useIsOwner'
import useIsWegoMerchantUser from '../hooks/useIsWegoMerchantUser'
import useQueryParameters from '../hooks/useQueryParameters'
import useSessionMerchantUser from '../hooks/useSessionMerchantUser'
import useSimplifyAge from '../hooks/useSimplifyAge'
import {
  Channels,
  ContactGroups,
  MerchantCustomerUserContactGroups,
  MerchantCustomerUsers,
  MerchantCustomerUserTags,
  Merchants,
  MerchantUsers,
  MutationRoot,
  QueryRoot,
  Tags,
} from '../types/graphqlSchema'
import {EMAIL_ADDRESS_REGEX} from '../utils/consts'
import {EventCategory, trackEvent} from '../utils/tracking'
import DeleteWithConfirmButton from './components/DeleteWithConfirmButton'
import ExtendedList from './components/ExtendedList'
import {CreateHeader, ShowHeader} from './components/ResourceHeader'

const MerchantCustomerUserCreate = () => {
  const translate = useTranslate()
  const styles = useStyles()
  const redirect = useRedirect()
  const notify = useNotify()
  const {identity: {merchantUserId} = {}} = useGetIdentity()
  const {data: contactGroups = []} = useGetList<ContactGroups>(
    'contact_groups',
    {
      filter: {'merchantUserContactGroups#merchantUserId': merchantUserId},
      sort: {field: 'name', order: 'ASC'},
    },
    {enabled: !!merchantUserId},
  )
  const [
    upsertMerchantCustomerUser,
  ] = useMutation<MutationRoot['insert_merchant_customer_users_one']>(
    MERCHANT_CUSTOMER_USER_UPSERT_MUTATION,
    {
      update: (cache, {data}) => {
        const merchantCustomerUser = data?.insert_merchant_customer_users_one
        cache.modify({
          fields: {
            merchantCustomerUsers: () => [merchantCustomerUser],
          },
          id: `customer_users:${merchantCustomerUser?.customerUser.id}`,
        })
      },
    },
  )
  const save = useCallback(async ({
    birthdayDate,
    ...formInputValues
  }: FieldValues) => {
    try {
      const {data} = await upsertMerchantCustomerUser({variables: {
        birthdayDate: birthdayDate || null,
        contactGroups: contactGroups.map(({id}) => ({contactGroupId: id})),
        ...formInputValues,
      }})
      notify('resources.merchant_customer_users.create.success')
      redirect(
        'edit', `/merchant_customer_users`, data?.insert_merchant_customer_users_one?.id,
      )
    }
    catch (e) {
      console.error(e)
      notify('resources.merchant_customer_users.create.error', {type: 'error'})
    }
  }, [contactGroups, notify, redirect, upsertMerchantCustomerUser])
  const {firstName, whatsappPhoneNumber} = useQueryParameters()
  return (
    <Create actions={<CreateHeader />}>
      <SimpleForm
        className={styles.form}
        defaultValues={{birthdayDate: '', firstName, whatsappPhoneNumber}}
        onSubmit={save}
        toolbar={<CreateToolbar />}
      >
        <Typography gutterBottom variant="subtitle2">
          {translate('resources.merchant_customer_users.create.personalData')}
        </Typography>
        <TextInput
          source="firstName"
          validate={required()}
        />
        <TextInput source="lastName" />
        <TextInput
          helperText={translate(
            'resources.merchant_customer_users.create.inputs.whatsappPhoneNumber.' +
            'helperText'
          )}
          source="whatsappPhoneNumber"
          validate={[
            regex(/^[+0-9()\s]{8,}$/, translate('validations.phoneNumber')),
            required(),
          ]}
        />
        <TextInput source="phoneNumber" />
        <TextInput
          parse={v => v?.toLowerCase() ?? ""}
          source="emailAddress"
          type="email"
          validate={[
            regex(EMAIL_ADDRESS_REGEX, translate('validations.emailAddress')),
          ]}
        />
        <DateInput source="birthdayDate" />
        <Typography gutterBottom variant="subtitle2">
          {translate('resources.merchant_customer_users.create.company')}
        </Typography>
        <TextInput source="companyName" />
        <TextInput source="role" />
        <TextInput source="trade" />
        <TextInput source="customerCode" />
        <TextInput source="companyStreet" />
        <TextInput source="companyHouseNumber" />
        <TextInput source="companyZipCode" />
        <TextInput source="companyCity" />
        <Typography gutterBottom variant="subtitle2">
          {translate('resources.merchant_customer_users.create.internal')}
        </Typography>
        <ReferenceInput
          perPage={-1}
          reference="merchant_users"
          sort={{field: 'firstName', order: 'ASC'}}
          source="primaryResponsibleMerchantUserId"
        >
          <SelectInput
            emptyText="ra.input.select.emptyText"
            className={styles.optInNullableInput}
            optionText={({firstName, lastName}) => `${firstName} ${lastName}`}
          />
        </ReferenceInput>
        <BooleanInput
          className={styles.optInInput}
          source="isMarketingConsentGranted"
        />
      </SimpleForm>
    </Create>
  )
}

const MerchantCustomerUsersEdit = () => {
  const styles = useStyles()
  const isDesktop = useIsDesktop()
  const isWegoMerchantUser = useIsWegoMerchantUser()
  const isOwner = useIsOwner()
  const canEdit = useHasPermission('edit', 'merchant_customer_users')
  return (
    <ExtendedEdit
      actions={
        <ShowHeader
          title={useTranslate()('resources.merchant_customer_users.edit.title')}
        />
      }
      aside={isDesktop ? <MerchantCustomerUserEditAside /> : undefined}
      className={styles.merchantCustomerUserEdit}
    >
      <SimpleForm
        className={styles.form}
        disabled={!canEdit}
        defaultValues={{birthdayDate: ''}}
        resetOptions={{keepDirtyValues: true}}
        toolbar={<EditToolbar />}
      >
        <Typography gutterBottom variant="subtitle2">
          {useTranslate()('resources.merchant_customer_users.edit.personalData')}
        </Typography>
        <TextInput defaultValue="" source="firstName" />
        <TextInput defaultValue="" source="lastName" />
        <TextInput readOnly source="whatsappPhoneNumber" />
        <TextInput defaultValue="" source="phoneNumber" />
        <TextInput
          parse={v => v?.toLowerCase() ?? ""}
          source="emailAddress"
          type="email"
          validate={[
            regex(EMAIL_ADDRESS_REGEX, useTranslate()('validations.emailAddress')),
          ]}
        />
        <DateInput source="birthdayDate" />
        <Typography gutterBottom variant="subtitle2">
          {useTranslate()('resources.merchant_customer_users.edit.company')}
        </Typography>
        <TextInput defaultValue="" source="companyName" />
        <TextInput defaultValue="" source="role" />
        <TextInput defaultValue="" source="trade" />
        <TextInput defaultValue="" source="customerCode" />
        <TextInput defaultValue="" source="companyStreet" />
        <TextInput defaultValue="" source="companyHouseNumber" />
        <TextInput defaultValue="" source="companyZipCode" />
        <TextInput defaultValue="" source="companyCity" />
        <Typography gutterBottom variant="subtitle2">
          {useTranslate()('resources.merchant_customer_users.edit.internal')}
        </Typography>
        <ReferenceInput
          perPage={-1}
          reference="merchant_users"
          sort={{field: 'firstName', order: 'ASC'}}
          source="primaryResponsibleMerchantUserId"
        >
          <SelectInput
            emptyText="ra.input.select.emptyText"
            optionText={u => `${u.firstName} ${u.lastName}`}
            readOnly={!canEdit}
          />
        </ReferenceInput>
        {((isWegoMerchantUser && isOwner) || !isWegoMerchantUser) && (
          <BooleanInput
            className={styles.optInInput}
            source="isMarketingConsentGranted"
          />
        )}
        {!isDesktop && <MerchantCustomerUserTagsList />}
        {!isDesktop && <MerchantCustomerUsersContactGroupsList />}
      </SimpleForm>
    </ExtendedEdit>
  )
}


const CreateToolbar = () => (
  <Toolbar>
    <SaveButton
      color="primary"
      icon={<SaveIcon />}
      // onClick={() => trackEvent('SAVE_CONTACT', EventCategory.CONTACT)}
      variant="contained"
    />
  </Toolbar>
)

const EditToolbar = () => {
  const translate = useTranslate()
  return (
    <>
      <AutoSave
        confirmationDuration={false}
        debounce={1000}
        typographyProps={{display: 'none'}}
      />
      {useHasPermission('delete', 'merchant_customer_users') && (
        <Toolbar>
          <DeleteWithConfirmButton
            confirmContent={
              translate('resources.merchant_customer_users.edit.confirmDeletion')
            }
            confirmTitle={record => [record.firstName, record.lastName].join(" ")}
          />
        </Toolbar>
      )}
    </>
  )
}

const MerchantCustomerUserEditAside = () => {
  const styles = useStyles()
  return (
    <div className={styles.merchantCustomerUserTagsAside}>
      <MerchantCustomerUserTagsList />
      <MerchantCustomerUsersContactGroupsList />
    </div>
  )
}

const MerchantCustomerUserTagsList = () => {
  const notify = useNotify()
  const {record} = useEditContext()
  const canEdit = useHasPermission('edit', 'merchant_customer_users')
  const {data: tags = [], refetch: refetchTags} = useGetList<Tags>(
    'tags',
    {
      // @ts-ignore
      pagination: {},
      sort: {field: 'name', order: 'ASC'},
    },
    {enabled: !!record},
  )
  const {
    data: merchantCustomerUserTags = [],
    refetch: refetchMerchantCustomerUserTags,
  } = useGetList<MerchantCustomerUserTags & {id: string}>(
    'merchant_customer_user_tags',
    {
      filter: {merchantCustomerUserId: record?.id},
      // @ts-ignore
      pagination: {},
      sort: {field: 'tagId', order: 'ASC'},
    },
    {enabled: !!record},
  )
  const [
    addMerchantCustomerUserTag,
    {isLoading: isAddingMerchantCustomerUserTag},
  ] = useCreate<MerchantCustomerUserTags & {id: string}>()
  const onAddMerchantCustomerUserTag = async (t: Tags) => {
    await addMerchantCustomerUserTag(
      'merchant_customer_user_tags',
      {data: {merchantCustomerUserId: record?.id, tagId: t.id}},
      {returnPromise: true}
    )
    notify('resources.merchant_customer_users.successMessages.tagAdded')
    refetchMerchantCustomerUserTags()
  }
  const [createTag, {isLoading: isCreatingTag}] = useCreate<Tags>()
  const onCreateTag = async (name: string) => {
    const tag = await createTag(
      'tags', {data: {name}}, {returnPromise: true},
    )
    refetchTags()
    await onAddMerchantCustomerUserTag(tag as Tags)
  }
  const [deleteMerchantCustomerUserTag] = useMutation(
    MERCHANT_CUSTOMER_USER_TAGS_DELETE_MUTATION,
    {
      onCompleted: () => {
        refetchMerchantCustomerUserTags()
        notify('resources.merchant_customer_users.successMessages.tagRemoved')
      },
    },
  )
  return (
    <MultipleChipSelect
      canCreateNewChoice
      canEditSelectedChoices={canEdit}
      choices={tags}
      isCreatingChoice={isCreatingTag || isAddingMerchantCustomerUserTag}
      onCreateNewChoice={onCreateTag}
      onDeleteChoice={c => deleteMerchantCustomerUserTag({
        variables: {
          merchantCustomerUserId: record?.id,
          tagId: c.tagId,
        },
      })}
      onSelectChoice={onAddMerchantCustomerUserTag}
      optionText={t => t?.name}
      selectedChoices={merchantCustomerUserTags}
      selectionToChoice={s => tags.find(t => t.id === s.tagId)!}
      title="Tags"
    />
  )
}

const MerchantCustomerUsersContactGroupsList = () => {
  const notify = useNotify()
  const translate = useTranslate()
  const {record} = useEditContext()
  const canEdit = useHasPermission('edit', 'merchant_customer_users')
  const {data: contactGroups = []} = useGetList<ContactGroups>(
    'contact_groups',
    {
      // @ts-ignore
      pagination: {},
      sort: {field: 'name', order: 'ASC'},
    },
    {enabled: !!record},
  )
  const {
    data: merchantCustomerUserContactGroups = [],
    refetch: refetchMerchantCustomerUserContactGroups,
  } = useGetList<MerchantCustomerUserContactGroups & {id: string}>(
    'merchant_customer_user_contact_groups',
    {
      filter: {merchantCustomerUserId: record?.id},
      // @ts-ignore
      pagination: {},
      sort: {field: 'contactGroupId', order: 'ASC'},
    },
    {enabled: !!record},
  )
  const [
    addMerchantCustomerUserToContactGroup,
    {isLoading: isAddingCustomerUserToContactGroup},
  ] = useCreate<MerchantCustomerUserContactGroups & {id: string}>()
  const onAddMerchantCustomerUserToContactGroup = async (c: ContactGroups) => {
    await addMerchantCustomerUserToContactGroup(
      'merchant_customer_user_contact_groups',
      {data: {contactGroupId: c.id, merchantCustomerUserId: record?.id}},
      {returnPromise: true}
    )
    notify('resources.merchant_customer_users.successMessages.contactGroupAdded')
    refetchMerchantCustomerUserContactGroups()
  }
  const [deleteMerchantCustomerUserContactGroup] = useMutation(
    MERCHANT_CUSTOMER_USER_CONTACT_GROUP_DELETE_MUTATION,
    {
      onCompleted: () => {
        refetchMerchantCustomerUserContactGroups()
        notify('resources.merchant_customer_users.successMessages.contactGroupRemoved')
      },
    },
  )
  return (
    <MultipleChipSelect
      canEditSelectedChoices={canEdit}
      choices={Object.values(contactGroups)}
      isCreatingChoice={isAddingCustomerUserToContactGroup}
      onDeleteChoice={c => deleteMerchantCustomerUserContactGroup({
        variables: {
          contactGroupId: c.contactGroupId,
          merchantCustomerUserId: record?.id,
        },
      })}
      onSelectChoice={onAddMerchantCustomerUserToContactGroup}
      optionText={c => c?.name}
      selectedChoices={Object.values(merchantCustomerUserContactGroups)}
      selectionToChoice={s => contactGroups.find(c => c.id === s.contactGroupId)!}
      title={translate('resources.contactGroups.name')}
    />
  )
}

const MerchantCustomerUsersList = () => {
  const canCreateMerchantCustomerUsers = useHasPermission(
    'create', 'merchant_customer_users'
  )
  const isDesktop = useIsDesktop()
  const styles = useStyles()
  const translate = useTranslate()
  const {
    identity: {merchantUserId} = {},
    isLoading: isLoadingSessionMerchantUser,
  } = useGetIdentity()
  const {
    data: {channels: inviteChannels} = {},
    loading: isLoadingChannels,
  } = useQuery<QueryRoot['channels']>(
    CHANNELS_QUERY, {skip: !merchantUserId, variables: {merchantUserId}}
  )
  const {
    data: {aggregated_merchant_customer_user_tags: tags = []} = {},
  } = useQuery<QueryRoot['aggregated_merchant_customer_user_tags']>(
    AGGREGATED_MERCHANT_CUSTOMER_USERS_TAGS_QUERY
  )
  if (isLoadingSessionMerchantUser || isLoadingChannels) {
    return <Loading />
  }
  return (
    <ExtendedList
      actionButtons={<ListActionButtons />}
      filters={[
        <FilterLiveSearch
          // @ts-ignore: 'InputProps' does not exist
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="secondary" />
              </InputAdornment>
            ),
            onKeyDown: e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
              }
            },
          }}
          alwaysOn
          key="fullText"
          source={[
            "fullText",
            "role@_ilike",
            "whatsappPhoneNumber@_ilike",
          ].join(',')}
        />,
        <AutocompleteInput
          choices={tags}
          className={styles.optInNullableInput}
          key="aggregatedMerchantCustomerUserTags#tagName"
          label={translate(
            'resources.aggregated_merchant_customer_user_tags.fields.tagName'
          )}
          optionText="tagName"
          optionValue = "tagName"
          source="aggregatedMerchantCustomerUserTags#tagName"
        />,
        <SelectInput
          choices={[
            {name: translate('ra.boolean.true'), value: 'true'},
            {name: translate('ra.boolean.false'), value: 'false'},
          ]}
          key="isMarketingConsentGranted"
          label={translate(
            'resources.merchant_customer_users.fields.isMarketingConsentGranted'
          )}
          isRequired
          optionText="name"
          optionValue="value"
          source="isMarketingConsentGranted"
          sx={{'& span[aria-hidden="true"]': {display: 'none'}}}
        />,
        <ReferenceInput
          filterToQuery={q => ({'firstName@_ilike,lastName@_ilike': q})}
          key="primaryResponsibleMerchantUserId"
          label={translate(
            'resources.merchant_customer_users.fields.primaryResponsibleMerchantUserId'
          )}
          perPage={100}
          reference="merchant_users"
          sort={{field: 'firstName', order: 'ASC'}}
          source="primaryResponsibleMerchantUserId"
        >
          <AutocompleteInput
            className={styles.optInNullableInput}
            optionText={({firstName, lastName}) => `${firstName} ${lastName}`}
          />
        </ReferenceInput>,
      ]}
      hasCreate={canCreateMerchantCustomerUsers}
      pagination={<Pagination rowsPerPageOptions={[]} />}
      perPage={50}
    >
      {isDesktop ?
        <DesktopDatagrid inviteChannels={inviteChannels} /> :
        <MobileDatagrid inviteChannels={inviteChannels} />
      }
    </ExtendedList>
  )
}

const MobileDatagrid: FC<MobileDatagridProps> = ({inviteChannels}) => {
  const styles = useStyles()
  return (
    <Datagrid bulkActionButtons={false} className={styles.table} rowClick={false}>
      <FunctionField<MerchantCustomerUsers>
        label="Name" render={u => [u?.firstName, u?.lastName].join(' ')}
      />
      <FunctionField<MerchantCustomerUsers>
        render={
          ({whatsappPhoneNumber} = {} as MerchantCustomerUsers) => (
            <ChatLinkButton
              customerUserWhatsappPhoneNumber={whatsappPhoneNumber}
              inviteChannels={inviteChannels}
            />
          )
        }
      />
      <EditButton
        icon={<ChevronRightIcon color="info" />}
        label=""
        // @ts-ignore
        source="editIconButton"
      />
    </Datagrid>
  )
}

const DesktopDatagrid: FC<DesktopDatagridProps> = ({inviteChannels}) => {
  const styles = useStyles()
  const canDelete = useHasPermission('delete', 'merchant_customer_users')
  return (
    <Datagrid
      bulkActionButtons={canDelete && <BulkDeleteButton />}
      className={styles.table}
      optimized
      rowClick={false}
    >
      <FunctionField<MerchantCustomerUsers>
        label={useTranslate()('resources.merchant_customer_users.fields.name')}
        render={u => [u?.firstName, u?.lastName].join(' ')}
        source="firstName"
      />
      <TextField source="whatsappPhoneNumber" />
      <TextField source="companyName" />
      <ReferenceField
        // @ts-ignore
        alwaysOn
        link={false}
        reference="merchant_users"
        sortBy="primaryResponsibleMerchantUser.lastName"
        source="primaryResponsibleMerchantUserId"
      >
        <FunctionField<MerchantUsers> render={u => `${u?.lastName}, ${u?.firstName}`} />
      </ReferenceField>
      <FunctionField<MerchantCustomerUsers>
        cellClassName={styles.columnToChatCell}
        render={
          u => (
            <ChatLinkButton
              customerUserWhatsappPhoneNumber={u?.whatsappPhoneNumber}
              inviteChannels={inviteChannels}
            />
          )
        }
      />
      <EditButton
        icon={<ChevronRightIcon color="info" />}
        label=""
        // @ts-ignore
        source="editIconButton"
      />
    </Datagrid>
  )
}

const ChatLinkButton: FC<ChatLinkButtonProps> = ({
  customerUserWhatsappPhoneNumber,
  inviteChannels = [],
}) => {
  const notify = useNotify()
  const translate = useTranslate()
  const redirect = useRedirect()
  const [startChatAndRedirect] = useMutation(
    START_CHAT_MUTATION,
    {
      onCompleted: ({startChat: {chat} = {}}) => {
        redirect(`/inbox/${chat?.channelId}/${chat?.id}`)
      },
      onError: e => {
        console.error(e)
        notify(
          'resources.merchant_customer_users.errorMessages.chatLink',
          {type: 'error'}
        )
      },
    }
  )
  return (
    <PopupState variant="popper">
      {popupState => (
        <>
          {inviteChannels.length > 1 && popupState.isOpen &&
            <ChatStartTracker />
          }
          <Link
            {...(
              inviteChannels.length > 1 ?
                bindTrigger(popupState) :
                {onClick: () => {
                  // trackEvent('START_CHAT', EventCategory.CONTACT, 'CONTACTS_PAGE')
                  inviteChannels.length && startChatAndRedirect({variables: {
                    chat: {
                      channelId: inviteChannels[0].id,
                      customerUserWhatsappPhoneNumber,
                    },
                  }})
                }}
            )}
          >
            {translate('resources.merchant_customer_users.action.seeChat')}
          </Link>
          {(inviteChannels.length > 1) &&
            <Menu {...bindMenu(popupState)}>
              <MenuItem disabled>
                {translate('resources.merchant_customer_users.list.chooseChannel')}
              </MenuItem>
              <Divider />
              {inviteChannels.map(c =>
                <MenuItem
                  key={c.id}
                  onClick={() => {
                    popupState.close()
                    startChatAndRedirect({variables: {
                      chat: {channelId: c.id, customerUserWhatsappPhoneNumber},
                    }})
                  }}
                >
                  {c.name}
                </MenuItem>
              )}
            </Menu>
          }
        </>
      )}
    </PopupState>
  )
}

const ChatStartTracker = () => {
  // We need to track the start chat event only ONCE when the
  // component is rendered. For that we need to register the following effect.
  useEffect(() => {
    trackEvent('START_CHAT', EventCategory.CONTACT, 'CONTACTS_PAGE')
  }, [])
  return null
}

const ListActionButtons = () => {
  const translate = useTranslate()
  const isDesktop = useIsDesktop()
  const simplifyAge = useSimplifyAge()
  const isListEmpty = !useListContext().total
  const notify = useNotify()
  const {data: {merchants: [{
    lastSalesforceSyncTimestamp, salesforceConnectionStatus,
  } = {} as Merchants] = []} = {}} = useQuery<QueryRoot['merchants']>(MERCHANT_QUERY)
  const hasSyncLabel = (salesforceConnectionStatus === 'CONNECTED') && isDesktop
  const canImportMerchantCustomerUsers = useHasPermission(
    'import', 'merchant_customer_users'
  )
  const canExportMerchantCustomerUsers = useHasPermission(
    'export', 'merchant_customer_users'
  )
  const exporter = useCallback(
    async (data, fetchRelatedRecords, dataProvider, resource) => {
      try {
        const [
          merchantUsers, merchantCustomerUserTags, merchantCustomerUserContactGroups,
        ] = await Promise.all([
          fetchRelatedRecords(
            data, 'primaryResponsibleMerchantUserId', 'merchant_users'
          ),
          fetchRelatedRecords(
            data, 'id', 'merchant_customer_user_tags'
          ),
          fetchRelatedRecords(
            data, 'id', 'merchant_customer_user_contact_groups'
          ),
        ])
        const customerUserIdToTags = Object
          .values<RaRecord>(merchantCustomerUserTags)
          .reduce(
            (result, {merchantCustomerUserId, tag}) => {
              result[merchantCustomerUserId] = [
                ...(result[merchantCustomerUserId] ?? []),
                tag.name,
              ]
              return result
            },
            {}
          )
        const customerUserIdToContactGroups = Object
          .values<RaRecord>(merchantCustomerUserContactGroups)
          .reduce(
            (result, {contactGroup, merchantCustomerUserId}) => {
              result[merchantCustomerUserId] = [
                ...(result[merchantCustomerUserId] ?? []),
                contactGroup.name,
              ]
              return result
            },
            {}
          )
        const merchantCustomerUsers = data.map(m => ({
          ...pick(m, [
            'whatsappPhoneNumber',
            'firstName',
            'lastName',
            'emailAddress',
            'responsibleMerchantUserEmailAddress',
            'isMarketingConsentGranted',
            'customerCode',
            'tags',
            'role',
            'trade',
            'birthdayDate',
            'companyCity',
            'companyName',
            'companyStreet',
            'companyZipCode',
            'companyHouseNumber',
          ]),
          contactGroups: customerUserIdToContactGroups[m.id]?.join(', '),
          responsibleMerchantUserEmailAddress:
            merchantUsers[m.primaryResponsibleMerchantUserId]?.emailAddress,
          tags: customerUserIdToTags[m.id]?.join(', '),
        }))
        defaultExporter(
          merchantCustomerUsers,
          fetchRelatedRecords,
          dataProvider,
          resource
        )
      }
      catch (e) {
        notify('ra.message.error', {type: 'error'})
        console.error(e)
      }
    },
    [notify],
  )
  return (
    <Box display="flex">
      {(canExportMerchantCustomerUsers && isDesktop) && (
        <ExportButton
          color="primary"
          disabled={isListEmpty}
          exporter={exporter}
          icon={<DownloadIcon/>}
          maxResults={false as any}
          size="medium"
          variant="outlined"
        />
      )}
      {(canImportMerchantCustomerUsers && isDesktop) && (
        <MerchantCustomerUserImportButton />
      )}
      {hasSyncLabel && (
        <Typography color="textSecondary" variant="body2">
          <SyncIcon color="textSecondary"/>
          {translate(
            'resources.merchant_customer_users.lastSalesforceSync',
            {
              age: lastSalesforceSyncTimestamp ?
                simplifyAge(lastSalesforceSyncTimestamp, {canShowToday: true}) :
                translate(
                  'resources.merchant_customer_users.lastSalesforceSyncPreparing'
                ),
            }
          )}
        </Typography>
      )}
    </Box>
  )
}

const MerchantCustomerUserImportButton = () => {
  const refresh = useRefresh()
  const isDesktop = useIsDesktop()
  const styles = useStyles()
  const translate = useTranslate()
  const {merchantUser = {} as MerchantUsers} = useSessionMerchantUser()
  const [isProgressDialogOpen, setIsProgressDialogOpen] = useState(false)
  const [
    importStatusMessage, setImportStatusMessage,
  ] = useState<ReactNode | undefined>(undefined)
  const closeProgressDialog = useCallback(() => {
    setImportStatusMessage(undefined)
    setIsProgressDialogOpen(false)
    refresh()
  }, [refresh])
  const [
    importMerchantCustomerUsers,
    {loading: isImportingMerchantCustomerUsers},
  ] = useMutation<MutationRoot['importMerchantCustomerUsers']>(
    MERCHANT_CUSTOMER_USERS_IMPORT_MUTATION,
    {
      onCompleted: ({
        importMerchantCustomerUsers: {
          importedMerchantCustomerUsersCount,
          invalidRowsCount,
          rowFailureReasons,
        } = {},
      }) => {
        setImportStatusMessage(!!invalidRowsCount ? (
          <div className={styles.merchantCustomerUsersImportStatusMessage}>
            <Typography>
              {translate(
                'resources.merchant_customer_users.import.status.ignored',
                {invalidRowsCount}
              )}
            </Typography>
            {rowFailureReasons?.map(r => (
              <Typography key={r} variant="body2">
                <WarningIcon color="warning" size="inherit"/>
                {translate(
                  `resources.merchant_customer_users.importRowFailureReasons.${r}`
                )}
              </Typography>
            ))}
          </div>
        ) : (
          <Typography>
            {translate(
              'resources.merchant_customer_users.import.success',
              {importedMerchantCustomerUsersCount}
            )}
          </Typography>
        ))
      },
      onError: e => {
        setImportStatusMessage(
          <Typography>
            <WarningIcon color="error" size="inherit"/>
            {translate(
              'resources.merchant_customer_users.import.error',
              {message: e.message}
            )}
          </Typography>
        )
      },
    }
  )
  const uploadMerchantCustomerUsersCsv = useCallback(([file]: FileAttachment[]) => {
    if (window.confirm(
      translate(
        'resources.merchant_customer_users.import.confirm',
        {filename: file.filename},
      )
    )) {
      setIsProgressDialogOpen(true)
      importMerchantCustomerUsers({
        variables: {
          csvBase64Content: file.encodedContent,
          merchantId: merchantUser?.merchant?.id,
          mimeType: file.mimeType,
        },
      })
    }
  }, [merchantUser, importMerchantCustomerUsers, translate])
  return (
    <>
      <FileUploadButton
        className={styles.merchantCustomerUserImportButton}
        loading={isImportingMerchantCustomerUsers}
        mimeTypes={[
          'application/vnd.oasis.opendocument.spreadsheet',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv',
        ]}
        onSelectFiles={uploadMerchantCustomerUsersCsv}
      >
        {isDesktop && translate('resources.merchant_customer_users.import.button')}
      </FileUploadButton>
      <ProgressDialog
        isProgressing={isImportingMerchantCustomerUsers}
        onClose={closeProgressDialog}
        open={isProgressDialogOpen}
        title={translate('resources.merchant_customer_users.import.progressDialog.title')}
      >
        {isImportingMerchantCustomerUsers ? (
          <Typography>
            {translate(
              'resources.merchant_customer_users.import.progressDialog.progressMessage'
            )}
          </Typography>
        ) : importStatusMessage}
      </ProgressDialog>
    </>
  )
}

const AGGREGATED_MERCHANT_CUSTOMER_USERS_TAGS_QUERY = gql`query{
  aggregated_merchant_customer_user_tags(distinct_on: tagName){tagName}
}`

const MERCHANT_QUERY = gql`query{
  merchants{id lastSalesforceSyncTimestamp salesforceConnectionStatus}
}`

const START_CHAT_MUTATION = gql`
  mutation($chat: Chat!){startChat(chat: $chat){chat{channelId id}}}
`

const CHANNELS_QUERY = gql`
  query($merchantUserId: uuid!){
    channels(where: {
      channelMerchantUsers: {merchantUserId: {_eq: $merchantUserId}}
    }){
      id name
    }
  }
`

const MERCHANT_CUSTOMER_USERS_IMPORT_MUTATION = gql`
  mutation (
    $csvBase64Content: String!
    $merchantId: uuid!
    $mimeType: String!
  ){
    importMerchantCustomerUsers(
      csvBase64Content: $csvBase64Content
      merchantId: $merchantId
      mimeType: $mimeType
    ){
      invalidRowsCount
      rowFailureReasons
      importedMerchantCustomerUsersCount
    }
  }
`

const MERCHANT_CUSTOMER_USER_TAGS_DELETE_MUTATION = gql`
  mutation($merchantCustomerUserId: uuid!, $tagId: uuid!){
    delete_merchant_customer_user_tags(where: {
      merchantCustomerUserId: {_eq: $merchantCustomerUserId},
      tagId: {_eq: $tagId}
    }){
      returning{tagId merchantCustomerUserId}
    }
  }
`


const MERCHANT_CUSTOMER_USER_CONTACT_GROUP_DELETE_MUTATION = gql`
  mutation($contactGroupId: uuid!, $merchantCustomerUserId: uuid!){
    delete_merchant_customer_user_contact_groups(where: {
      contactGroupId: {_eq: $contactGroupId}
      merchantCustomerUserId: {_eq: $merchantCustomerUserId},
    }){
      returning{contactGroupId merchantCustomerUserId}
    }
  }
`

const MERCHANT_CUSTOMER_USER_UPSERT_MUTATION = gql`
  mutation (
    $birthdayDate: date
    $companyCity: String
    $companyHouseNumber: String
    $companyName: String
    $companyStreet: String
    $companyZipCode: String
    $contactGroups: [merchant_customer_user_contact_groups_insert_input!]!
    $customerCode: String
    $emailAddress: String
    $firstName: String
    $lastName: String
    $isMarketingConsentGranted: Boolean
    $phoneNumber: String
    $primaryResponsibleMerchantUserId: uuid
    $role: String
    $trade: String
    $whatsappPhoneNumber: String
  ) {
    insert_merchant_customer_users_one(
      object: {
        birthdayDate: $birthdayDate
        companyCity: $companyCity
        companyHouseNumber: $companyHouseNumber
        companyName: $companyName
        companyStreet: $companyStreet
        companyZipCode: $companyZipCode
        merchantCustomerUserContactGroups: {
          data: $contactGroups
          on_conflict: {
            constraint: merchant_customer_user_contact_groups_pkey,
            update_columns: [],
          }
        }
        customerCode: $customerCode
        emailAddress: $emailAddress
        firstName: $firstName
        isMarketingConsentGranted: $isMarketingConsentGranted
        lastName: $lastName
        phoneNumber: $phoneNumber
        primaryResponsibleMerchantUserId: $primaryResponsibleMerchantUserId
        role: $role
        trade: $trade
        whatsappPhoneNumber: $whatsappPhoneNumber
      },
      on_conflict: {
        constraint: merchant_customer_users_customer_user_id_merchant_id_key
        update_columns: [
          birthdayDate
          companyCity
          companyHouseNumber
          companyName
          companyStreet
          companyZipCode
          customerCode
          emailAddress
          firstName
          lastName
          phoneNumber
          primaryResponsibleMerchantUserId
          role
          trade
        ]
      }
    ){
      id customerUser{id}
    }
  }
`

const useStyles = makeStyles(theme => ({
  columnToChatCell: {
    textAlign: 'center !important' as 'center',
  },
  form: {
    '& .MuiFormHelperText-root': {
      color: theme.palette.text.secondary,
      ...theme.typography.caption,
    },
  },
  merchantCustomerUserEdit: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
    },
  },
  merchantCustomerUserImportButton: {
    '& .MuiButton-label > .MuiSvgIcon-root:not(:last-child)': {
      marginRight: 0,
    },
  },
  merchantCustomerUserTagsAside: {
    boxSizing: 'border-box',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.remSpacing(10),
      paddingRight: theme.remSpacing(10),
      width: '50%',
    },
  },
  merchantCustomerUsersImportStatusMessage: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.remSpacing(1),
  },
  optInInput: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.remSpacing(2),
    },
  },
  optInNullableInput: {
    width: '100% !important',
  },
  table: {
    '& .RaDatagrid-table': {
      [theme.breakpoints.down('lg')]: {
        '& th:nth-of-type(1)': {
          width: '50%',
        },
        '& th:nth-of-type(2)': {
          width: theme.remSpacing(5),
        },
        '& th:nth-of-type(3)': {
          width: theme.remSpacing(5),
        },
      },
    },
  },
}))

interface MobileDatagridProps {
  inviteChannels: Channels[] | undefined
}

type DesktopDatagridProps = MobileDatagridProps

interface ChatLinkButtonProps {
  customerUserWhatsappPhoneNumber: string | undefined,
  inviteChannels?: Channels[]
}

export {
  MerchantCustomerUserCreate,
  MerchantCustomerUsersEdit,
  MerchantCustomerUsersList,
}
