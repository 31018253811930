import {difference, uniq} from 'lodash-es'
import {useCallback, useMemo} from 'react'
import {useGetList, useListContext} from 'react-admin'

const useSelectAllRowIdsInAllPages = ({isEnabled}: UseSelectAllRowIdsInAllPagesProps) => {
  const {
    filter,
    filterValues,
    onSelect: selectIds,
    resource,
    selectedIds,
  } = useListContext()
  const {data, isFetching} = useGetList(
    resource,
    // @ts-ignore
    {filter: {...filter, ...filterValues}, pagination: {}},
    {enabled: isEnabled},
  )
  const ids = useMemo(() => data?.map(d => d.id), [data])
  const selectAllRowIdsInAllPages = useCallback(
    () => ids && selectIds(uniq([...selectedIds, ...ids])),
    [ids, selectedIds, selectIds]
  )
  const unselectAllRowIdsInAllPages = useCallback(() => selectIds([]), [selectIds])
  const isAllRowIdsInAllPagesSelected = useMemo(
    () => isEnabled && !!ids?.length && !difference(ids, selectedIds).length,
    [isEnabled, ids, selectedIds]
  )
  return {
    isAllRowIdsInAllPagesSelected,
    loading: isFetching,
    selectAllRowIdsInAllPages,
    unselectAllRowIdsInAllPages,
  }
}

type UseSelectAllRowIdsInAllPagesProps = {
  isEnabled: boolean
}

export default useSelectAllRowIdsInAllPages
