import {makeStyles} from '@mui/styles'
import {AutoSave} from '@react-admin/ra-form-layout'
import {required, SimpleForm, TextInput, useTranslate} from 'react-admin'

import ExtendedEdit from '../components/ExtendedEdit'
const Edit = ({disabled, id}) => {
  const styles = useStyles()
  return (
    <>
      <ExtendedEdit
        className={styles.root}
        id={id}
        resource="channel_chat_bot_users"
        redirect={false}
        title={useTranslate()('channel_chat_bot_users.title')}
      >
        <SimpleForm
          disabled={disabled}
          resetOptions={{keepDirtyValues: true}}
          toolbar={(
            <AutoSave
              confirmationDuration={false}
              debounce={1000}
              typographyProps={{display: 'none'}}
            />
          )}
        >
          <TextInput label="Opt-In Trigger" source="optInTrigger" validate={required()} />
          <TextInput
            disabled={disabled}
            multiline
            rows={5}
            source="optInTriggerAutoresponse"
            validate={required()}
          />
          <TextInput
            disabled={disabled}
            source="optOutTrigger"
            validate={required()}
          />
          <TextInput
            disabled={disabled}
            multiline
            rows={5}
            source="optOutTriggerAutoresponse"
            validate={required()}
          />
        </SimpleForm>
      </ExtendedEdit>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTypography-root': {
      paddingBottom: theme.remSpacing(1),
    },
  },
}))

export {Edit}
